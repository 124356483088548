import React from "react";
import Navbar from "../../layouts/frontend/Navbar";

const Home = () => {
  return (
    <div>
      <Navbar />
      <h1>I am Home</h1>
    </div>
  );
};

export default Home;
